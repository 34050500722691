<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <div>
        <el-button class="back-btn" @click="$goBack" icon="el-icon-arrow-left" size="small" type="primary" plain></el-button>
        <label class="label">人像识别详情</label>
      </div>
    </div>
    <div class="js-common-content face-infos">
      <ul class="info-list">
        <li>
          <label>识别比对：</label>
          <div>
            <img class="img" :src="faceInfo.captureUrl" alt="">
            <p class="text">采集照片</p>
          </div>
          <div class="score">
            <span>相似度</span>
            <span>{{ faceInfo.similarity }}%</span>
          </div>
          <div>
            <img class="img" :src="faceInfo.photo" alt="">
            <p class="text">比对照片</p>
          </div>
        </li>
        <li>
          <label>身份信息：</label>
          <div>
            <p><label>姓名</label><span>{{ faceInfo.afr.name }}</span></p>
            <p><label>性别</label><span>{{ sex }}</span></p>
            <p><label>民族</label><span>{{ faceInfo.afr.nation }}</span></p>
            <p><label>警示</label><span>
                <el-button v-if="faceInfo.status" type="danger" size="small">有</el-button>
                <el-button v-else type="primary" size="small">无</el-button>
              </span>
            </p>
            <p><label>生日</label><span>{{ faceInfo.afr.birthday }}</span></p>
            <p><label>身份</label><span>{{ faceInfo.afr.idcard }}</span></p>
            <p><label>地址</label><span>{{ faceInfo.afr.address }}</span></p>
          </div>
        </li>
        <li>
          <label>最近识别时间：</label>
          <span>{{ faceInfo.lastRecognTime }}</span>
        </li>
        <li>
          <label>识别时间：</label>
          <span>{{ faceInfo.recognTime }}</span>
        </li>
        <li>
          <label>创建人：</label>
          <span>{{ faceInfo.createdName }}</span>
        </li>
        <li>
          <label>反馈：</label>
          <span>{{ faceInfo.remark }}</span>
        </li>
      </ul>
      <el-table class="js-table" :data="faceInfo.matchRecord" empty-text="暂无数据">
        <el-table-column label="采集照片">
          <template slot-scope="scope">
            <img class="img" :src="scope.row.captureUrl" alt="">
          </template>
        </el-table-column>
        <el-table-column label="相似度">
          <template slot-scope="scope">
            {{scope.row.similarity}}%
          </template>
        </el-table-column>
        <el-table-column prop="recognTime" label="识别时间"></el-table-column>
        <el-table-column label="身份信息" width="200">
          <template slot-scope="scope">
            <div class="post-card">
              <!-- <i class="el-icon-postcard"></i> -->
              <div>
                <!-- <p>{{scope.row.afr.name}}</p> -->
                <p>{{scope.row.afr.idcard}}</p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="警示">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status" type="danger" size="small">有</el-button>
            <el-button  v-else type="primary" size="small">无</el-button>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-delete" @click.stop="deleteItem(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <common-delete v-model="deleteDialogVisible" @handleDelete="handleDelete"></common-delete>
  </div>
</template>
<script>
import CommonDelete from '@/components/CommonDelete'
export default {
  name: '',
  components: {
    CommonDelete
  },
  data() {
    return {
      faceInfo:{
        captureUrl:'',
        similarity:'',
        photo:'',
        afr:{
          name:'',
          gender:'',
          nation:'',
          birthday:'',
          idcard:'',
          address:''
        },
        status:'',
        lastRecognTime:'',
        recognTime:'',
        createdName:'',
        remark:'',
        records:[{
          captureUrl:'',
          afr:{
            name:'',
            idcard:'',
          },
          status:''
        }]
      },
      tableData: [],
      deleteDialogVisible: false,
      currentID: '',
      currentItem: ''
    }
  },
  computed:{
    sex(){
      let sexText = ''
      if(this.faceInfo.afr.gender === 1){
        sexText = '男'
      }else if(this.faceInfo.afr.gender === 2){
        sexText = '女'
      }else{
        sexText = '未知'
      }
      return sexText
    }
  },
  mounted() {
    this.currentID = this.$route.query.id
    this.getDetails()
  },
  methods: {
    getDetails(){
      this.$axios({
        method: 'get',
        url: `api/csp/afr/v1/afrRecord/${this.currentID}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.faceInfo = data.data
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    deleteItem(item){
      this.currentItem = item
      this.deleteDialogVisible = true
    },
    handleDelete(){
      this.$axios({
        method: 'delete',
        url: `api/csp/afr/v1/afrRecord/${this.currentItem.id}`
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.deleteDialogVisible = false
          this.$message({
            type: 'success',
            message: '删除成功！',
            duration: 1000
          })
          this.getDetails()
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variable.scss';
.face-infos{
  margin-top: 20px;
  padding-top: 0;
}
.post-card{
  display: flex;
  align-items: center;
  text-align: left;
}
.info-list{
  padding: 30px 60px;
  background: #fff;
  font-size: 14px;
  li{
    display: flex;
    margin-bottom: 10px;
    line-height: 32px;
  }
  label{
    width: 150px;
    display: inline-block;
  }
  .img{
    width: 150px;
    height: 150px;
    vertical-align: middle;
  }
  .text{
    text-align: center;
  }
  .score{
    margin: 30px 40px;
    width: 90px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 20px;
    background: $jsTheme;
    border-radius: 50%;
    color: #fff;
  }
}
.js-table{
  margin-top: 10px;
}
</style>
