var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c(
          "div",
          [
            _c("el-button", {
              staticClass: "back-btn",
              attrs: {
                icon: "el-icon-arrow-left",
                size: "small",
                type: "primary",
                plain: "",
              },
              on: { click: _vm.$goBack },
            }),
            _c("label", { staticClass: "label" }, [_vm._v("人像识别详情")]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content face-infos" },
        [
          _c("ul", { staticClass: "info-list" }, [
            _c("li", [
              _c("label", [_vm._v("识别比对：")]),
              _c("div", [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.faceInfo.captureUrl, alt: "" },
                }),
                _c("p", { staticClass: "text" }, [_vm._v("采集照片")]),
              ]),
              _c("div", { staticClass: "score" }, [
                _c("span", [_vm._v("相似度")]),
                _c("span", [_vm._v(_vm._s(_vm.faceInfo.similarity) + "%")]),
              ]),
              _c("div", [
                _c("img", {
                  staticClass: "img",
                  attrs: { src: _vm.faceInfo.photo, alt: "" },
                }),
                _c("p", { staticClass: "text" }, [_vm._v("比对照片")]),
              ]),
            ]),
            _c("li", [
              _c("label", [_vm._v("身份信息：")]),
              _c("div", [
                _c("p", [
                  _c("label", [_vm._v("姓名")]),
                  _c("span", [_vm._v(_vm._s(_vm.faceInfo.afr.name))]),
                ]),
                _c("p", [
                  _c("label", [_vm._v("性别")]),
                  _c("span", [_vm._v(_vm._s(_vm.sex))]),
                ]),
                _c("p", [
                  _c("label", [_vm._v("民族")]),
                  _c("span", [_vm._v(_vm._s(_vm.faceInfo.afr.nation))]),
                ]),
                _c("p", [
                  _c("label", [_vm._v("警示")]),
                  _c(
                    "span",
                    [
                      _vm.faceInfo.status
                        ? _c(
                            "el-button",
                            { attrs: { type: "danger", size: "small" } },
                            [_vm._v("有")]
                          )
                        : _c(
                            "el-button",
                            { attrs: { type: "primary", size: "small" } },
                            [_vm._v("无")]
                          ),
                    ],
                    1
                  ),
                ]),
                _c("p", [
                  _c("label", [_vm._v("生日")]),
                  _c("span", [_vm._v(_vm._s(_vm.faceInfo.afr.birthday))]),
                ]),
                _c("p", [
                  _c("label", [_vm._v("身份")]),
                  _c("span", [_vm._v(_vm._s(_vm.faceInfo.afr.idcard))]),
                ]),
                _c("p", [
                  _c("label", [_vm._v("地址")]),
                  _c("span", [_vm._v(_vm._s(_vm.faceInfo.afr.address))]),
                ]),
              ]),
            ]),
            _c("li", [
              _c("label", [_vm._v("最近识别时间：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceInfo.lastRecognTime))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("识别时间：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceInfo.recognTime))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("创建人：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceInfo.createdName))]),
            ]),
            _c("li", [
              _c("label", [_vm._v("反馈：")]),
              _c("span", [_vm._v(_vm._s(_vm.faceInfo.remark))]),
            ]),
          ]),
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.faceInfo.matchRecord,
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "采集照片" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("img", {
                          staticClass: "img",
                          attrs: { src: scope.row.captureUrl, alt: "" },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "相似度" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.similarity) + "% ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "recognTime", label: "识别时间" },
              }),
              _c("el-table-column", {
                attrs: { label: "身份信息", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("div", { staticClass: "post-card" }, [
                          _c("div", [
                            _c("p", [_vm._v(_vm._s(scope.row.afr.idcard))]),
                          ]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "警示" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.status
                          ? _c(
                              "el-button",
                              { attrs: { type: "danger", size: "small" } },
                              [_vm._v("有")]
                            )
                          : _c(
                              "el-button",
                              { attrs: { type: "primary", size: "small" } },
                              [_vm._v("无")]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.deleteItem(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-delete", {
        on: { handleDelete: _vm.handleDelete },
        model: {
          value: _vm.deleteDialogVisible,
          callback: function ($$v) {
            _vm.deleteDialogVisible = $$v
          },
          expression: "deleteDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }